import Head from "next/head";
import React from "react";
import LoginComponent from "../components/Login/Login";
import { UserGuard } from "../Guard/UserGuard";
import UnderMaintainance from "./UnderMaintainance";

const Login: React.FC = (): JSX.Element => {
  const isUnderMaintainance = process.env.NEXT_PUBLIC_IS_UNDER_MAINTENANCE;
  return (
    <UserGuard>
      <Head>
        <title>Login</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content="Login page" />
      </Head>
      {/* <PageNotFound /> */}
      {isUnderMaintainance ? <UnderMaintainance /> : <LoginComponent />}
      {/* <LoginComponent /> */}
    </UserGuard>
  );
};

export default Login;
